






























































import { defineComponent, ref, computed } from '@vue/composition-api'

import { endpoints, convertToWidthCharacter } from 'utils'
import { QuickInput as QuickInputType } from 'typings'
import { useQuery, useShowError } from 'hooks'

import { QuickInputDialog, LoaderComponent } from 'components'

const QuickInput = defineComponent({
  components: {
    QuickInputDialog,
    LoaderComponent
  },
  setup(props, { root }) {
    const { $toast } = root

    const searchInfo = ref('')
    const isAdd = ref(true)
    const isShow = ref(false)
    const quickInputProp = ref<QuickInputType | null>(null)

    const {
      data: quickInputs,
      loading,
      error,
      mutate: mutateQuickInput
    } = useQuery(endpoints.QUICK_INPUT)

    useShowError($toast, error)

    const handleOpenQuickInputDialog = (data?: QuickInputType) => {
      isShow.value = true
      isAdd.value = !data
      if (data) {
        quickInputProp.value = data
      }
    }

    const closeDialog = () => {
      isShow.value = false
    }

    const searchQuickInput = computed(() => {
      if (!quickInputs.value) return []

      return quickInputs.value.filter((quickInput: QuickInputType) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const searchStr = quickInput.searchStr.toLowerCase()
        return searchStr.includes(text)
      })
    })

    return {
      loading,
      quickInputs,
      quickInputProp,
      handleOpenQuickInputDialog,
      isAdd,
      isShow,
      closeDialog,
      searchInfo,
      searchQuickInput,
      mutateQuickInput
    }
  }
})

export default QuickInput
